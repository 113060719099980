<template>
  <v-card flat height="100%" class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-color-main caption font-weight-bold">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text class="text-color-main mt-5">
      <div class="font-weight-bold d-flex justify-space-between">
        <span>
          Audience
        </span>
        <span v-if="totalUsers">
          Size
        </span>
      </div>
      <div>
        <v-radio-group v-model="selectedType">
          <div class="d-flex justify-space-between align-center">
            <v-radio label="Everyone" value="all"></v-radio>
            <span class="body-2 font-weight-bold" v-if="totalUsers">{{
              totalUsers
            }}</span>
          </div>
          <div class="my-2"></div>
          <div>
            <div class="d-flex justify-space-between align-center mb-5">
              <v-radio label="Users" value="users" class="mr-5"></v-radio>
              <v-spacer></v-spacer>
              <v-text-field
                filled
                hide-details
                dense
                label="Search"
                append-icon="mdi-magnify"
                v-model="search"
                autofocus
                clearable
                @click:clear="search = null"
                :disabled="selectedType === 'all'"
                class="recipient-search-input"
              ></v-text-field>
            </div>
            <div class="pl-4">
              <v-list
                rounded
                dense
                :style="{ height: '500px' }"
                class="overflow-y-auto"
                color="#F1F1F1"
                :disabled="selectedType === 'all'"
              >
                <v-subheader v-if="search"
                  >Search results for: {{ search }}</v-subheader
                >
                <template v-for="(user, index) in users">
                  <v-list-item :key="index">
                    <template v-slot:default>
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          dense
                          :value="user.id"
                          v-model="selectedUsersID"
                          :disabled="selectedType === 'all'"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ user.first_name
                          }}{{
                            user.last_name ? ` ${user.last_name}` : ""
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          user.email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
                <infinite-loading
                  @infinite="loadUsers"
                  :identifier="userListScrollOptions.id"
                >
                  <template v-slot:no-results>
                    <span></span>
                  </template>
                  <template v-slot:no-more>
                    <span></span>
                  </template>
                </infinite-loading>
              </v-list>
            </div>
          </div>
        </v-radio-group>
        <!--        <v-radio-group-->
        <!--          v-model="selectedType"-->
        <!--          mandatory-->
        <!--          dense-->
        <!--          hide-details-->
        <!--          @change="(selectedRecipients = []), changeRecipient()"-->
        <!--        >-->
        <!--          &lt;!&ndash; <div-->
        <!--            v-for="(type, key) in types"-->
        <!--            :key="key"-->
        <!--            class="d-flex justify-space-between align-center my-2"-->
        <!--          > &ndash;&gt;-->
        <!--          <div class="d-flex justify-space-between align-center my-2">-->
        <!--            <v-radio :label="types[0].text" :value="types[0].value"></v-radio>-->
        <!--            <span class="body-2 font-weight-bold">-->
        <!--              {{ types[0].size }}-->
        <!--            </span>-->
        <!--            &lt;!&ndash; <template v-if="types[0].value === 'selected'">-->
        <!--              <div class="recipient-search-input">-->
        <!--                <v-text-field-->
        <!--                  placeholder="Search..."-->
        <!--                  dense-->
        <!--                  v-model="search"-->
        <!--                  filled-->
        <!--                  hide-details-->
        <!--                  append-outer-icon="mdi-magnify"-->
        <!--                  class="rounded-lg"-->
        <!--                  @click:append-outer="searchData()"-->
        <!--                ></v-text-field>-->
        <!--              </div>-->
        <!--            </template> &ndash;&gt;-->
        <!--          </div>-->
        <!--          <div class="d-flex justify-space-between align-center my-2">-->
        <!--            <v-radio label="Select" value="selected"></v-radio>-->
        <!--            <template>-->
        <!--              <div class="recipient-search-input">-->
        <!--                <v-text-field-->
        <!--                  placeholder="Search..."-->
        <!--                  dense-->
        <!--                  v-model="search"-->
        <!--                  filled-->
        <!--                  hide-details-->
        <!--                  append-outer-icon="mdi-magnify"-->
        <!--                  class="rounded-lg"-->
        <!--                  @click:append-outer="searchData()"-->
        <!--                ></v-text-field>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </div>-->
        <!--          <div class="pl-4">-->
        <!--            <div v-for="(recipient, key) in recipients" :key="key">-->
        <!--              <div class="d-flex justicy-space-between align-center">-->
        <!--                <v-checkbox-->
        <!--                  v-model="selectedRecipients"-->
        <!--                  :label="recipient.name"-->
        <!--                  :value="recipient.id"-->
        <!--                  :disabled="-->
        <!--                    selectedType === 'everyone' || selectedType === 'users'-->
        <!--                  "-->
        <!--                  dense-->
        <!--                  hide-details-->
        <!--                  class="mt-0"-->
        <!--                ></v-checkbox>-->
        <!--                <v-spacer></v-spacer>-->
        <!--                <span class="body-2 pt-1 font-weight-bold">-->
        <!--                  {{ recipient.size }}-->
        <!--                </span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="d-flex justify-space-between align-center my-2">-->
        <!--            <v-radio label="Users" value="users"></v-radio>-->
        <!--            <template>-->
        <!--              <div class="recipient-search-input">-->
        <!--                <v-text-field-->
        <!--                  placeholder="Search..."-->
        <!--                  dense-->
        <!--                  v-model="searchString"-->
        <!--                  filled-->
        <!--                  hide-details-->
        <!--                  append-outer-icon="mdi-magnify"-->
        <!--                  class="rounded-lg"-->
        <!--                  @click:append-outer="searchUser(searchString)"-->
        <!--                ></v-text-field>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </div>-->
        <!--          <div class="search-list">-->
        <!--            <ul style="list-style:none; padding-left: 15px">-->
        <!--              <li v-for="(item, i) of users" :key="i">-->
        <!--                <v-checkbox-->
        <!--                  :label="item.first_name + ' ' + item.last_name"-->
        <!--                  :disabled="selectedType !== 'users'"-->
        <!--                  v-model="selectedUsers"-->
        <!--                  :value="item.id"-->
        <!--                  dense-->
        <!--                  hide-details-->
        <!--                  @change="$emit('update', selectedUsers, 'users')"-->
        <!--                  class="user-checkbox"-->
        <!--                ></v-checkbox>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </v-radio-group>-->
        <!--        -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const userRepository = RepositoryFactory.get("user");

export default {
  props: {
    recipientData: {
      type: Object,
      required: true
    },

    shouldReset: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    title: "Choose your recipients",
    subtitle: "Send to everyone. or use an audience to target the right users.",
    selectedType: "all",
    selectedRecipients: [],
    // types: [
    //   {
    //     text: "Everyone",
    //     value: "everyone",
    //     size: null
    //   },
    //   {
    //     text: "Select",
    //     value: "selected",
    //     size: null
    //   }
    // ],
    // recipients: [
    //   {
    //     id: 1,
    //     name: "Pastor",
    //     size: 8
    //   },
    //   {
    //     id: 2,
    //     name: "Dream Team",
    //     size: 24
    //   },
    //   {
    //     id: 3,
    //     name: "Connect Group",
    //     size: 12
    //   },
    //   {
    //     id: 7,
    //     name: "Connect Group 1",
    //     size: 12
    //   },
    //   {
    //     id: 4,
    //     name: "Connect Group 2",
    //     size: 12
    //   },
    //   {
    //     id: 5,
    //     name: "Connect Group 3",
    //     size: 12
    //   },
    //   {
    //     id: 6,
    //     name: "Connect Group 4",
    //     size: 12
    //   }
    // ],
    //
    search: null,
    searchString: "",
    users: [],
    selectedUsers: [],
    userListScrollOptions: {
      page: 1,
      id: +new Date(),
      perPage: 15
    },
    totalUsers: null,
    selectedUsersID: []
  }),

  watch: {
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.users = [];
        this.userListScrollOptions.page = 1;
        this.userListScrollOptions.id += 1;
      }, 800);
    },

    selectedType(value) {
      this.$emit("update:recipientData", {
        type: value,
        data: this.selectedUsersID
      });
    },

    selectedUsersID(value) {
      this.$emit("update:recipientData", {
        type: this.selectedType,
        data: value
      });
    },

    shouldReset(shouldReset) {
      if (shouldReset) {
        this.selectedType = "all";
        this.selectedUsersID = [];
      }
    }
  },

  methods: {
    // setEveryoneCount() {
    //   let total = this.recipients.reduce((accumulator, currentValue) => {
    //     return (accumulator += currentValue.size);
    //   }, 0);
    //
    //   this.types[0].size = total;
    // },
    //
    // searchData() {
    //   console.log(this.search);
    // },
    // searchUser(val) {
    //   if (val) {
    //     let params = {
    //       search: val
    //     };
    //     userRepository.search({ params }).then(response => {
    //       let {
    //         data: { responseData: data }
    //       } = response;
    //       console.log(data);
    //       this.users = data.data;
    //     });
    //   }
    // },
    loadUsers($state) {
      let params = {
        search: this.search,
        per_page: this.userListScrollOptions.perPage,
        page: this.userListScrollOptions.page
      };
      userRepository.search({ params }).then(response => {
        let {
          data: { responseData: data }
        } = response;
        const users = data.data;
        if (users.length === 15) {
          this.userListScrollOptions.page += 1;
          this.users = [...this.users, ...users];
          $state.loaded();
          return;
        }
        $state.complete();
        this.users = [...this.users, ...users];
      });
    },
    changeRecipient() {
      console.log("change " + this.selectedType);
    },
    async getTotalUsers() {
      let params = {
        search: null,
        per_page: 15,
        page: 1
      };
      userRepository.search({ params }).then(response => {
        let {
          data: { responseData: data }
        } = response;
        this.totalUsers = data.total || null;
      });
    }
  },
  async mounted() {
    // this.setEveryoneCount();
    await this.getTotalUsers();
  }
};
</script>

<style lang="scss" scoped>
.recipient-search-input {
  width: 150px;
}

.user-checkbox {
  margin-top: 0;

  ::v-deep .v-messages {
    display: none;
  }
}
</style>
